<template>
    <div>
        <div class="section_title">
            {{block.name}}
            <span :class="block.perform_type == 2 ? 'circuit' : 'progression' ">{{ block.perform_type == 2 ? 'Circuit' : 'Progression' }}</span>
            <div class="filter" @click="circuitFilterDropdown = !circuitFilterDropdown" v-if="block.perform_type == 2" v-click-outside="closeFilterCircuitDropdown">
                Sort by {{ params.filter }} <i class="fas fa-angle-down"></i>
                <div class="dropdown_wpr" :class="{'active' : circuitFilterDropdown}">
                    <ul>
                        <li v-for="(filter, f) in circuitFilter" :key="f" @click="params.filter = filter;">Sort by {{ filter }}</li>
                    </ul>
                </div>
            </div>
            <div class="filter" @click="progressionFilterDropdown = !progressionFilterDropdown" v-if="block.perform_type == 1" v-click-outside="closeFilterProgressionDropdown">
                Sort by {{ params.filter }} <i class="fas fa-angle-down"></i>
                <div class="dropdown_wpr" :class="{'active' : progressionFilterDropdown}">
                    <ul>
                        <li v-for="(filter, f) in progressionFilters" :key="f" @click="params.filter = filter;">Sort by {{ filter }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <swiper :slidesPerView="'auto'" :spaceBetween="30" :navigation='true' class="leaderboard_slider">
            <swiper-slide v-for="(exercise, e) of stats.exercises" :key="e">
                <div class="leaderboard">
                    <div class="board_ttl">
                        <svg width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.60945 0.514477C2.34421 1.89866 0 4.92421 0 9.04485C0 11.6161 0.805006 13.4612 2.68437 15.1967C3.90814 16.3268 3.97997 16.483 3.52732 17.0274C2.37911 18.4087 0.735551 22.1567 0.394033 24.1723C-0.129763 27.2667 0.399793 30.1161 2.09214 33.3084C3.67132 36.287 6.54237 39 8.7663 39.6152C10.6189 40.128 23.3682 40.1283 25.2615 39.6161C26.2023 39.3611 27.3458 38.6064 28.7769 37.2951C32.5713 33.8187 34.3984 28.853 33.606 24.1723C33.2645 22.1567 31.6209 18.4087 30.4727 17.0274C30.02 16.483 30.0919 16.3268 31.3156 15.1967C33.2021 13.4546 34 11.618 34 9.01826C34 5.37257 31.9014 2.29101 28.432 0.842688C26.7224 0.129008 26.3138 0.0983455 17.5082 0.0238799C9.40089 -0.0449538 8.16119 0.0110521 6.60945 0.514477ZM25.7931 4.21272C28.3812 4.69049 30.3646 7.8387 29.5874 10.2347C29.19 11.459 27.38 13.3986 26.6343 13.3986C26.4138 13.3986 25.5651 13.0538 24.7485 12.6323C20.3369 10.3552 13.6631 10.3552 9.25147 12.6323C7.35991 13.6085 7.18509 13.5979 5.88576 12.4249C4.41601 11.098 3.9444 9.54922 4.46548 7.76204C4.90695 6.24739 6.66604 4.45896 7.93589 4.23306C9.17863 4.01217 24.612 3.99433 25.7931 4.21272ZM16.3224 13.6871C17.0623 13.8848 16.9739 13.9414 15.6217 14.1357C10.4295 14.8829 5.89898 18.8718 4.37773 24.0365L3.77872 26.0702L3.89019 23.2543C4.01114 20.1994 4.40721 19.1825 6.33095 16.9876C8.53456 14.4736 13.3324 12.8889 16.3224 13.6871Z" fill="#2F7EED"/>
                        </svg>
                        {{exercise.name}}
                    </div>
                    <ul v-if="block.perform_type == 2">
                        <li class="header"><span>Work</span><span>Load</span><span>Rest</span></li>
                        <li v-for="(contact, c) in exercise.stats" :key="c">
                            <span class="board_pos">{{c + 1}}</span>
                            <img :src="contact.photo" alt="">
                            <h5>{{ contact.name }}</h5>
                            <div class="marks"><span>{{ contact.work }}</span><span>{{ contact.load }}</span><span>{{ contact.rest }}</span></div>
                        </li>
                    </ul>
                    <ul v-if="block.perform_type == 1">
                        <li class="header"><span>Sets</span><span>Reps</span><span>Load</span><span>Rest</span></li>
                        <li v-for="(contact, c) in exercise.stats" :key="c">
                            <span class="board_pos">{{c + 1}}</span>
                            <img :src="contact.photo" alt="">
                            <h5>{{ contact.name }}</h5>
                            <div class="marks"><span><span class="v_tag pointer" v-if="contact.variable" @click="viewResponse(contact)">V</span>{{ contact.sets }}</span><span><span class="v_tag pointer" v-if="contact.variable" @click="viewResponse(contact)">V</span>{{ contact.reps }}</span><span>{{ contact.set_load ? contact.set_load : contact.load }}</span><span>{{ contact.rest }}</span></div>
                        </li>
                    </ul>
                </div>
            </swiper-slide>
        </swiper>
        <div class="modal add_step secondary" v-if="toggleResponse" :class="{active : toggleResponse}">
            <div class="modal_container">
                <h2 class="model-header">
                    Sets ({{ selectedResponse.name }})
                    <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                </h2>
                <div class="modal_body">
                    <div class="step_content mb-4">
                        <div class="table_wpr">
                            <table class="task_history">
                                <thead>
                                    <tr>
                                        <th class="set">Sets</th>
                                        <th>Reps</th>
                                        <th v-if="selectedResponse.is_tempo">Tempo</th>
                                        <th>Load</th>
                                        <th>Rest Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="selectedResponse.response && selectedResponse.response.set_settings">
                                        <tr v-for="(set, s) in selectedResponse.response.set_settings" :key="s">
                                            <td class="set">{{ s + 1 }}</td>
                                            <td>{{ set.reps }}</td>
                                            <td v-if="selectedResponse.is_tempo">
                                                {{ set.tempo }}
                                            </td>
                                            <td>
                                                <div class="flex justify-content-center" v-if="selectedResponse.load_unit_code.toLowerCase() !== '1rm'">
                                                    {{ selectedResponse.load_unit_code.toLowerCase() !== 'bw' ? set.load_number : '' }}
                                                    &nbsp;{{ selectedResponse.load_unit_code.toLowerCase()  !== 'bw' ? selectedResponse.load_unit_code.toLowerCase() : 'Bodyweight' }}
                                                </div>
                                                <div class="flex justify-content-center" v-else>
                                                    <template v-if="set.set_load">
                                                        {{ selectedResponse.exercise_load && selectedResponse.exercise_load.weight ? set.set_load : '' }}
                                                    </template>
                                                    <template v-else>
                                                        {{ selectedResponse.exercise_load && selectedResponse.exercise_load.weight ? Math.round(set.load_number * 0.01 * parseInt(selectedResponse.exercise_load.weight)) : '' }}
                                                    </template>
                                                    &nbsp;{{ selectedResponse.exercise_load && selectedResponse.exercise_load.weight ? selectedResponse.exercise_load.unit : '% ' + selectedResponse.load_unit_code.toLowerCase() }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex justify-content-center">
                                                    {{ set.rest_minutes }}:{{ set.rest_seconds }}
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr v-for="(set, s) in selectedResponse.set_settings" :key="s">
                                            <td class="set">{{ s + 1 }}</td>
                                            <td>{{ set.reps }}</td>
                                            <td v-if="selectedResponse.is_tempo">
                                                {{ set.tempo }}
                                            </td>
                                            <td>
                                                <div class="flex justify-content-center" v-if="selectedResponse.load_unit_code.toLowerCase() !== '1rm'">
                                                    {{ selectedResponse.load_unit_code.toLowerCase() !== 'bw' ? set.load_number : '' }}
                                                    &nbsp;{{ selectedResponse.load_unit_code.toLowerCase()  !== 'bw' ? selectedResponse.load_unit_code.toLowerCase() : 'Bodyweight' }}
                                                </div>
                                                <div class="flex justify-content-center" v-else>
                                                    {{ selectedResponse.exercise_load && selectedResponse.exercise_load.weight ? Math.round(set.load_number * 0.01 * parseInt(selectedResponse.exercise_load.weight)) : '' }}
                                                    &nbsp;{{ selectedResponse.exercise_load && selectedResponse.exercise_load.weight ? selectedResponse.exercise_load.unit : '% ' + selectedResponse.load_unit_code.toLowerCase() }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex justify-content-center">
                                                    {{ set.rest_minutes }}:{{ set.rest_seconds }}
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import SwiperCore, { Navigation } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import 'swiper/swiper-bundle.min.css'
    SwiperCore.use([Navigation])

    export default {
        name: 'block-analytic-stats',

        data () {
            return {
                params: {
                    workout_id: 0,
                    workout_block_id: 0,
                    filter: 'Work',
                },
                circuitFilterDropdown: false,
                progressionFilterDropdown: false,
                circuitFilter: ['Work', 'Load', 'Rest'],
                progressionFilters: ['Sets', 'Reps', 'Load', 'Rest'],
                stats: [],
                loader: false,
                mounted: false,
                selectedResponse: {},
                toggleResponse: false,
            };
        },

        props: {
            workout: Object,
            block: Object,
        },

        watch: {
            'params.filter' (filter) {
                const vm = this;

                if (vm.mounted) {
                    vm.getBlockStats();
                }
            },
        },

        components: {
            Swiper,
            SwiperSlide,
        },

        mounted () {
            const vm = this;

            vm.mounted = false;
            vm.params.workout_id        = vm.workout.id;
            vm.params.workout_block_id  = vm.block.id;

            if (vm.block.perform_type == 1) {
                vm.params.filter  = 'Sets';
            } else {
                vm.params.filter  = 'Work';
            }

            vm.getBlockStats();

            setTimeout(() => {
                vm.mounted = true;
            }, 1000);
        },

        methods: {
            ...mapActions({
                getWorkoutBlockStats: 'workoutModule/getWorkoutBlockStats',
            }),

            getBlockStats () {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.params));
                params.filter = params.filter.toLowerCase();
                vm.loader = true;

                vm.getWorkoutBlockStats(params).then((stats) => {
                    if (stats) {
                        vm.stats = stats;
                    }

                    vm.loader = false;
                });
            },

            closeFilterCircuitDropdown () {
                const vm = this;

                vm.circuitFilterDropdown = false;
            },

            closeFilterProgressionDropdown () {
                const vm = this;

                vm.progressionFilterDropdown = false;
            },

            viewResponse (resp) {
                const vm = this;

                vm.selectedResponse = resp;
                vm.toggleResponse   = true;
            },

            closeModal () {
                const vm = this;

                vm.toggleResponse   = false;
            },
        },
    }
</script>

<style scoped>
.section_title {
    font-size: 20px;
    line-height: 26px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 12px;
}

.section_title .circuit,
.section_title .progression {
    height: 20px;
    padding: 0 10px;
    border-radius: 12px;
    background: #eef5ff;
    border: 1px solid #c5ddff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2f7eed;
}

.section_title .filter,
.workout_title .filter {
    padding: 3px 0;
    margin-left: auto;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    position: relative;
    z-index: 3;
    cursor: pointer;
}

.section_title .filter .dropdown_wpr,
.workout_title .filter .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
}

.section_title .filter .dropdown_wpr ul li,
.workout_title .filter .dropdown_wpr ul li {
    border: 0;
    text-align: left;
}

.section_title .filter i,
.workout_title .filter i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}
.leaderboard_wpr {
    margin: 15px 0 40px 0;
}
.show_hide {
    font-size: 11px;
    line-height: 14px;
    background: #fff;
    border: 1px solid #f5f5f5;
    padding: 7px 15px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.leaderboard {
    border: 0;
}

.leaderboard .board_ttl {
    justify-content: flex-start;
    gap: 10px;
    line-height: 18px;
    border: 0;
    padding: 12px 20px 12px 15px;
}

.leaderboard .board_ttl i {
    color: #5a5a5a;
    cursor: pointer;
    margin-left: auto;
}

.leaderboard .board_ttl svg {
    height: 18px;
    width: auto;

}

.leaderboard ul {
    background: #fff;
    height: 200px;
}

.leaderboard ul li h5 {
    margin-right: auto;
}

.leaderboard ul li .streaks {
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.leaderboard ul li .ratings {
    display: flex;
    gap: 1px;
}

.leaderboard ul li .ratings span {
    color: #2f7eed;
    font-size: 8px;
}

.leaderboard_slider {
    padding: 15px 20px 40px 20px;
    margin: 0 -20px
}

.leaderboard_slider :deep(.swiper-slide) {
    width: auto
}

.leaderboard_slider :deep(.leaderboard) {
    width: 355px;
}

.leaderboard_slider :deep(.leaderboard ul) {
    padding-top: 0;
    height: 210px;
}

.leaderboard_slider :deep(.leaderboard li.header) {
    display: flex;
    justify-content: flex-end;
    background: #fbfbfb;
    position: sticky;
    top: 0;
    z-index: 2;
}

.leaderboard_slider :deep(.leaderboard li.header span) {
    width: 40px;
    font-size: 10px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    padding: 0 5px;
    display: flex;
    justify-content: center;
}

.leaderboard_slider :deep(.leaderboard li .marks) {
    display: flex;
    justify-content: flex-end;
}

.leaderboard_slider :deep(.leaderboard li .marks span) {
    width: 40px;
    padding: 0 5px;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    justify-content: center;
}

.leaderboard_slider :deep(.swiper-button-next.swiper-button-disabled),
.leaderboard_slider :deep(.swiper-button-prev.swiper-button-disabled) {
    opacity: 0;
}

.leaderboard_slider :deep(.swiper-button-next) {
    right: 0;
}

.leaderboard_slider :deep(.swiper-button-prev) {
    left: 0;
}

.leaderboard_slider :deep(.swiper-button-next:after),
.leaderboard_slider :deep(.swiper-button-prev:after) {
    font-size: 18px;
    font-weight: 600;
}

.leaderboard li .marks span.v_tag {
    width: 14px;
    height: 14px;
    background: #999;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    color: #fff;
    margin-right: 5px;
    border-radius: 50%;
}

.step_content {
    padding: 20px 30px 10px 30px;
    flex: 1;
}

.close_btn {
    position: absolute;
    right: 15px;
    left: auto;
    top: 15px;
    border: 0;
    background: rgba(50,55,59,.6);
    font-size: 12px;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 20px;
}

.table_wpr {
    overflow-x: auto;
}

table.task_history {
    min-width: 350px;
    width: 100%;
    border-spacing: 1px;
    background: #eaeaea;
}
.task_history th, .task_history td {
    padding: 10px 0;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}
.task_history thead tr {
    border-bottom: 1px solid #e9e9e9;
}
.task_history td.exercise_title {
    padding: 7px 15px;
    background: #fbfbfb;
    font-size: 11px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
}
.task_history td.exercise_title .title {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}
.task_history td.exercise_title .title i {
    color: #5a5a5a;
}
.task_history td.exercise_title img {
    display: none;
}
.task_history td.exercise_title.show img {
    display: block;
}
.task_history td  {
    width: 10%;
    background: #fff;
    color: #777;
}

h2.model-header {
    width: 100%;
    padding: 15px 25px;
    padding-bottom: 0;
    margin-bottom: 0;
}

@media(max-width: 375px){
    .leaderboard_slider :deep(.leaderboard) {
        width: 335px;
    }
}
</style>